import React, { useEffect, useState } from "react"
import { DatePicker as Dtpicker } from '@material-ui/lab';
import { TextField } from "@material-ui/core";

export const DatePicker = ({defaultValue, name, details = false, caption = "Fecha", formik, placeholder, onChange, minDate, invalidDateMessage, minDateMessage, maxDateMessage}) => {

  const [inputValue, setInputValue] = useState(formik.values[name] || (defaultValue && eval(defaultValue)))
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
    timer && clearTimeout(timer);
    setTimer(setTimeout(() => formik.setFieldValue(name, inputValue), 250));
  }, [inputValue])


  return (
    <div style={{ padding: 8 }}>

      <Dtpicker
        disabled={details}
        label={caption}
        value={inputValue}
        onChange={(value)=>{ 
          setInputValue(value)
        }}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </div>
  )
}

