import React, { useEffect } from 'react';
import { DataGridIndex } from '../../helpers/DataGridIndex';
import useStyles from './Style';
import { CardContent, CardHeader, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';


const List = (props) => {

  const history = useHistory();
  const { removeItem, snack, config, index, list } = props;
  const classes = useStyles();

  useEffect(()=>{
    index()
      .then(()=>{
        console.log("ok")
      })
      .catch(()=>{
        console.log("error")
      })
  },[])

  const handleAddClick = () => {
    history.push("Add");
  }

  const handleEditClick = (params, event) => {
    history.push(`Edit/${params.id}`);
  }

  const handleDetailsClick = (params, event) => {
    history.push(`Details/${params.id}`);
  }

  const handleDeleteClick = (params, event) => {
    event.preventDefault();
    const itemDescription = params
      .api
      .getAllColumns()
      .filter(x => x.field !== "acciones")
      .map(x => {
        const text = x.renderCell ? x.renderCell(params) : params.row[x.field];
        return text ? `${x.headerName}: ${text}` : '';
      }) 
      .join("<br/>");
    Swal.fire({
      icon: 'question',
      title: 'Eliminar elemento',
      html: `Está seguro de eliminar este elemento?<br/> <span>${itemDescription}</span>`,
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cerrar',
    })
    .then((result) => {
      if (result.isConfirmed) {
        removeItem(params.id).then(
          ()=>{
            index();
            snack.openSuccess('El elemento se eliminó correctamente.');
          },
          error => snack.openAxiosError(error)
        );
      }
    })
  }
  
  /*
  const handleChange = (event) => {
      handleControlChange[event.target.name] && handleControlChange[event.target.name](event.target.value); 
      formik.handleChange(event);
  }
  */

  return (
    <>
      <CardHeader
        action={
          <Button onClick={handleAddClick}>
            Agregar
          </Button>
        }
        title={config.caption}
      />
      <CardContent>
        <DataGridIndex fileName={config.caption} rows={list} columns={config.columns({onDetails: handleDetailsClick, onEdit: handleEditClick, onDelete: handleDeleteClick})}/>
      </CardContent>
    </>
  );
};

export default List;