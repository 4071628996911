import axios from 'axios';

const API_URL = window.config.API_URL; 

export const login = (usuario, password) => {
  return axios.post(`${API_URL}/Account/Login`, {usuario, password});
}

export const reloadToken = (token) => {
  return axios.post(`${API_URL}/Account/ReLogin`, {}, {headers: {Authorization : token}});
}

export const ApiBase = (controller) => {

  const index = (token) => {
    return axios.get(`${API_URL}/${controller}`, {headers: {Authorization : token}});
  }
  
  const editForm = (id, token) => {
    return axios.get(`${API_URL}/${controller}/Edit/${id}`, {headers: {Authorization : token}});
  }
  
  const edit = (id, data, token) => {
    return axios.put(`${API_URL}/${controller}/${id}`, data, {headers: {Authorization : token}});
  }
  
  const createForm = (token) => {
    return axios.get(`${API_URL}/${controller}/Create`, {headers: {Authorization : token}});
  }
  
  const create = (data, token) => {
    return axios.post(`${API_URL}/${controller}`, data, {headers: {Authorization : token}});
  }
  
  const remove = (id, token) => {
    return axios.delete(`${API_URL}/${controller}/${id}`, {headers: {Authorization : token}});
  }
  
  return {index, editForm, edit, createForm, create, remove};
}

export const useApiSitios = () => {
  return ApiBase("Sitios");
}

export const useApiTiposSitios = () => {
  return ApiBase("TiposSitios");
}

export const useApiMediosTransporte = () => {
  return ApiBase("MediosTransporte");
}

export const useApiAccesoriosCuidados = () => {
  return ApiBase("AccesoriosCuidados");
}

export const useApiTiposAccesoriosCuidados = () => {
  return ApiBase("TiposAccesoriosCuidados");
}

export const useApiUsuarios = () => {
  return ApiBase("Usuarios");
}

export const useApiTraslados = () => {

  const controller = "Traslados"; 

  const transferForm = (id, token) => {
    return axios.get(`${API_URL}/${controller}/Transfer/${id}`, {headers: {Authorization : token}});
  }

  const transfer = (id, idUsuarioCamillero, idUsuarioConsola, token) => {
    return axios.put(`${API_URL}/${controller}/Transfer/${id}/${idUsuarioCamillero}/${idUsuarioConsola}`, {}, {headers: {Authorization : token}});
  }

  return {transfer, transferForm};

}

export const trasladosActivos = (token) => {
  return axios.get(`${API_URL}/Traslados/Activos`, {headers: {Authorization : token}});
}

export const trasladosFinalizados = (token) => {
  return axios.get(`${API_URL}/Traslados/Finalizados`, {headers: {Authorization : token}});
}

export const trasladosCreateConfirm = (data, token) => {
  return axios.post(`${API_URL}/Traslados/Create`, data,  {headers: {Authorization : token}});
}

export const trasladosCancelConfirm = (data, token) => {
  //console.log(data)
  return axios.put(`${API_URL}/Traslados/Cancel/`, data,  {headers: {Authorization : token}});
}

export const trasladosEditData = (id, token) => {
  return axios.get(`${API_URL}/Traslados/Edit/${id}`, {headers: {Authorization : token}});
}

export const trasladosEditConfirm = (id, data, token) => {
  return axios.put(`${API_URL}/Traslados/Edit/${id}`, data,  {headers: {Authorization : token}});
}

export const trasladosSetPriority = (id, token) => {
  return axios.put(`${API_URL}/Traslados/SetPriority/${id}`, {},  {headers: {Authorization : token}});
}

export const MenuSSO = (token) => {
  return axios.get(`${API_URL}/FeatureAccess/menu`, {headers: {Authorization : token}});
}


const AbmApiBase = (controller, token) => {
  
  const index = () => {
    return axios.get(`${API_URL}/${controller}`, {headers: {Authorization: token}});
  }
  
  const editForm = (id, ) => {
    return axios.get(`${API_URL}/${controller}/Edit/${id}`, {headers: {Authorization: token}});
  }
  
  const editItem = (id, data) => {
    return axios.put(`${API_URL}/${controller}/${id}`, data, {headers: {Authorization: token}});
  }
  
  const addForm = () => {
    return axios.get(`${API_URL}/${controller}/Create`, {headers: {Authorization: token}});
  }
  
  const addItem = (data) => {
    return axios.post(`${API_URL}/${controller}`, data, {headers: {Authorization: token}});
  }
  
  const removeItem = (id) => {
    return axios.delete(`${API_URL}/${controller}/${id}`, {headers: {Authorization: token}});
  }
  
  return {index, editForm, editItem, addForm, addItem, removeItem};
}

export const useAbmApi = (controller, getState) => AbmApiBase(controller, getState().auth.token);