import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSnackContext } from '../../contexts/Snack'
import { useHistory, useParams } from 'react-router';
import { Backdrop, Button, CardContent, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { createValidationObject } from '../../../redux/utils';
import { Field } from '../../helpers';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
    opacity: 1,
    backgroundColor: "#000000"
  },
}));


export const DetailsForm = (props) => {

  const { config, loaded, editForm, addForm, loadedProcessed, data, item } = props;
  let { id } = useParams();
  const [internalLoaded, setInternalLoaded] = useState(false);
  const formik = {};

  useEffect(() => {
    editForm(id);
  }, [])

  useEffect(() => {
    if(loaded){
      loadedProcessed()
      setInternalLoaded(true); 
    }
  }, [loaded])

  return (
    <>
      {internalLoaded && <RealForm {...{...props, ...{id}}}/>}
      {!internalLoaded && <FakeForm {...props}/>}
    </>
  )
}

export const FakeForm = (props) => {
  const { config } = props;
  return (
    <>
      <CardContent>
        <Grid container spacing={2}>
          {
            config.fields.map(x=>
              <Field fake={true} field={x} />
            )
          }
        </Grid>
      </CardContent>
    </>
  );
}

export const RealForm = (props) => {

  const classes = useStyles();
  
  let history = useHistory();
  const { snack, id, config, item, data, editItem, addItem } = props;

  const handleSaveClick = (event) => {
    event.preventDefault();
    formik.submitForm();
  }

  const formik = useFormik({
    initialValues: item,
    validateOnChange: false,
  });

  return (
    <>
      <CardContent>
        <Grid container spacing={2}>
          {
            config.fields.map(x=>
              <Field details={true} {...{formik, data, item}} field={x} />
            )
          }
        </Grid>
      </CardContent>
    </>
  );
}


export default DetailsForm;

