import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { createConfig } from "../../redux/utils";
import { toAbsoluteRouterUrl, toAbsoluteUrl } from "../../_metronic/_helpers";
import { ErrorMessage } from "../components/Error";
import { MenuSSO } from "../services/ApiService";
import { useAuthContext } from "./Auth";
import { useSnackContext } from "./Snack";

const menuInit = {
  homeUrl: "/",
  brandImg: "",
  loginImg: "",
  sectionRoot: {
    caption: "",
    features: [
      {name: "", caption: "", url: "", hasSubmenu: false, icon: ""},
    ]
  },
  sections: [
    { 
      caption: "", 
      features: [
        {
          caption: "", 
          icon: "",
          features: [
            {name: "", caption: "",  url: "", exact: false, icon: ""}
          ]       
        },
      ]
    }
  ]
}

export const useFeatureAccess = () => {
  const [menu, setMenu] = useState({});
  const [config, setConfig] = useState({controllers:[]});
  const [loaded, setLoaded] = useState(false);
  const {openAxiosError} = useSnackContext();

  useEffect(()=>{
    setConfig(createConfig({menu}));
  }, [menu])
  
  useEffect(()=>{
    setLoaded(true);
  }, [config])

  const load = (token) => {
    MenuSSO(token)
    .then(response => {
      setMenu(response.data);
    }).catch(error => {
      openAxiosError(error);
    });
  }

  return { config, menu, loaded, load };
}

export const FeatureAccessContext = React.createContext(useFeatureAccess); 

export const useFeatureAccessContext = () => { 
  return useContext(FeatureAccessContext); 
};

export const FeatureAccessProvider = ({ children }) => {

  const {auth} = useAuthContext();
  const featureAccess = useFeatureAccess();


  useEffect(()=>{
    featureAccess.load(auth.token);
  },[auth.token])

  return (
    <>
    <FeatureAccessContext.Provider value={featureAccess}>
      {children}
    </FeatureAccessContext.Provider>
    </>
  );
};


export const RedirectToHome = () => {
  const {menu, loaded} = useFeatureAccessContext();
  return (
    <>
      { loaded && menu.homeUrl &&
        <Redirect to={toAbsoluteRouterUrl(menu.homeUrl)}/>
      }
    </>
  )
}