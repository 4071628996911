import React, { useContext, useState } from "react";

export const usePageTitle = () => {
  const [header, setHeader] = useState({title: "", subTitle: ""});
  const setTitle = (text) => {
    setHeader({...header, title: text});
  }
  const setSubTitle = (text) => {
    setHeader({...header, subTitle: text});
  }
  const setPageTitle = (title, subTitle) => {
    setHeader({title: title, subTitle: subTitle});
  }
  return { header, setTitle, setSubTitle, setPageTitle};
}

export const PageTitleContext = React.createContext(usePageTitle); 

export const usePageTitleContext = () => { 
  return useContext(PageTitleContext); 
};

export const PageTitleProvider = ({ children }) => {
  const pageTitle = usePageTitle();
  return (
    <PageTitleContext.Provider value={pageTitle}>
      <title>{pageTitle.header.title}</title>
      {children}
    </PageTitleContext.Provider>
  );
};
