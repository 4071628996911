import React from "react";
import { useEffect, useState } from "react"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { MapMarker, MapBuscadorControl, MapMarcaProvincias} from "./";

export const MapGiz = (props) => {
  const { lat, lng, formik } = props;

  const [state, setState] = useState({lat: -35.101934, lng: -63.896484, loaded: false})
  useEffect(()=>{
    const getLat = formik.values[lat];
    const getLng = formik.values[lng];
    setState({
      lat: (getLat && getLng) ? getLat : -35.101934,
      lng: (getLat && getLng) ? getLng : -63.896484, 
      loaded: true,
    })
  },[])

  const onChange = (latLng) => {
    formik.setValues({...formik.values, [lat]: latLng.lat, [lng]: latLng.lng})
  }

  return (
    <>
      {state.loaded &&
        <MapContainer 
          center={[ state.lat, state.lng ]}
          zoom={13} 
          scrollWheelZoom={false} 
          id="map" 
          style={{width:"100%", height: 500}}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MapMarker lat={state.lat} lng={state.lng} onChange={onChange}/>
          <MapBuscadorControl/>
          <MapMarcaProvincias/>
        </MapContainer>       
      }
    </>  
  )

}




