
import { useAbmApi as api } from '../../app/services/ApiService';
import { abmConstants } from '../constants';
import { baseAction, toDispatch } from '../utils';

/**
 * @param {*} controller string | nombre del controller en la api 
 * @returns 
 */
const abmActions = (controller) => {

  
  const constants = abmConstants(controller);
  
  const index = () => (dispatch, getState) => {
    return baseAction({
      fn: api(controller, getState).index,
      ...constants.INDEX,
      dispatch,
      getState
    });
  }

  const basicIndex = () => (dispatch, getState) => {
    return baseAction({
      fn: api(controller, getState).basicIndex,
      ...constants.INDEX,
      dispatch,
      getState
    });
  }

  const getItem = (id) => (dispatch, getState) => {
    return baseAction({
      fn: () => api(controller, getState).getItem(id),
      ...constants.GET_ITEM,
      dispatch,
      getState
    });
  }

  const addForm = () => (dispatch, getState) => {
    return baseAction({
      fn: api(controller, getState).addForm,
      ...constants.ADD_FORM,
      dispatch,
      getState
    });
  }

  const editForm = (id) => (dispatch, getState) => {
    return baseAction({
      fn: () => api(controller, getState).editForm(id),
      ...constants.EDIT_FORM,
      dispatch,
      getState
    });
  }

  const addItem = (data) => (dispatch, getState) => {
    return baseAction({
      fn: () => api(controller, getState).addItem(data),
      ...constants.ADD_ITEM,
      dispatch,
      getState
    });
  }

  const editItem = (id, data) => (dispatch, getState) => {
    return baseAction({
      fn: () => api(controller, getState).editItem(id, data),
      ...constants.EDIT_ITEM,
      dispatch,
      getState
    });
  }

  const removeItem = (id) => (dispatch, getState) => {
    return baseAction({
      fn: () => api(controller, getState).removeItem(id),
      ...constants.REMOVE_ITEM,
      dispatch,
      getState
    });
  }

  const loadedProcessed = () => (dispatch, getState) => {
    dispatch(toDispatch(constants.LOADED_PROCESSED))
  }

  return {
    index,
    basicIndex,
    getItem,
    addItem,
    editItem,
    addForm,
    editForm,
    removeItem,
    loadedProcessed,
  };

}

export default abmActions;