import { Button, CardContent, CardHeader } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import { Form } from './Form';
import useStyles from './Style';

const Add = (props) => {
  const { config } = props;
  let history = useHistory();
  const classes = useStyles();

  /*
  const handleChange = (event) => {
      handleControlChange[event.target.name] && handleControlChange[event.target.name](event.target.value); 
      formik.handleChange(event);
  }
  */

  const handleBackClick = (event) => {
    event.preventDefault();
    history.goBack();
  }

  return (
    <>
      <CardHeader
        action={
          <Button onClick={handleBackClick}>
            Volver
          </Button>
        }
        title={config.caption}
        subheader="Agregar"
      />
      <Form {...props}/>
    </>
  );
};

export default Add;