import React from "react";
import { useEffect } from "react"
import { useMap } from 'react-leaflet'
import provincias from "../assets/json/provincias-geo.json";
import L from 'leaflet';

export const MapMarcaProvincias = () => {
  const map = useMap()
  const onEachFeature = (feature, layer) => {
		var popupContent = feature.properties.nombre_completo;
		layer.bindPopup(popupContent);
	}
  useEffect(()=>{
    L.geoJSON([provincias], {
      style: function (feature) {
        return feature.properties && feature.properties.style;
      },
      onEachFeature: onEachFeature,
      pointToLayer: (feature, latlng) => {
        return L.circleMarker(latlng, {
          radius: 8,
          fillColor: "red",
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8,
        });  
      }
    }).addTo(map);
  
  }, []); 
  return (<></>)
}