import React from "react";
import { useEffect, useState } from "react"
import { useMap } from 'react-leaflet'
import L from 'leaflet';
import { MapSelectGiz } from "./MapSelectGiz";
import localidadesJson from "../assets/json/localidades.json";
import './extensions/array.extensions.js';

const group = localidadesJson
  .localidades
  .groupBy((x) => x.provincia, (provincia, group) => ({
    nombre: provincia.nombre, 
    latlngs: group.map(f => [f.centroide.lat,f.centroide.lon]),
    items: group.groupBy((y) => y.municipio, (municipio, group2) => ({
      nombre: municipio.nombre,
      latlngs: group2.map(f => [f.centroide.lat,f.centroide.lon]),
      items: group2.map(f => ({
        nombre: f.nombre,
        latlngs: [[f.centroide.lat,f.centroide.lon]],          
      })),
    }))
  }));

export const MapBuscadorControl = (props) => {

  const map = useMap();

  const [state, setState] = useState({
    loaded: false,
    provincias: group.map(x => x.nombre), 
    municipios: [], 
    localidades: [], 
    provinciasItems: group, 
    municipiosItems: [], 
    localidadesItems: [],
  });

  const [values, setValues] = useState({provincia:"", municipio:"", localidad:""});

  useEffect(() => {
    setValues({...values, municipio: "", localidad: ""})
    if(!values.provincia){
      setState({
        ...state, 
        municipiosItems: [], 
        municipios: [],
        localidadesItems:  [],
        localidades: [],
      });
      return;
    }
    const filtroProv = state.provinciasItems.filter(x => x.nombre === values.provincia)[0];
    const polyline = L.polyline(filtroProv.latlngs, {color: 'red'});
    map.fitBounds(polyline.getBounds());
    setState({
      ...state, 
      municipiosItems: filtroProv.items, 
      municipios: filtroProv.items.map(x => x.nombre),
      localidadesItems: [],
      localidades: [],
    })  

  }, [values.provincia])

  useEffect(() => {
    
    if(!values.municipio){
      setState({
        ...state, 
        localidadesItems: [],
        localidades: [],
      });
      setValues({...values, localidad: ""})
      return;
    }
    console.log(values);
    const filtroMun = state.municipiosItems.filter(x => x.nombre === values.municipio)[0];
    const polyline = L.polyline(filtroMun.latlngs, {color: 'red'});
    map.fitBounds(polyline.getBounds());
    filtroMun.items.length == 1 && map.setZoom(13);

    setState({
        ...state,
        localidadesItems: filtroMun.items,
        localidades: filtroMun.items.map(x => x.nombre),
    })
    setValues({...values, localidad: filtroMun.items.length > 1 ? "" : filtroMun.items[0].nombre})

  }, [values.municipio])

  useEffect(() => {
    if(!values.localidad) return;
    const filtroLoc = state.localidadesItems.filter(x => x.nombre === values.localidad)[0];
    const polyline = L.polyline(filtroLoc.latlngs, {color: 'red'});
    map.fitBounds(polyline.getBounds());
    map.setZoom(13);
  }, [values.localidad])

  const handleProvinciaChange = (event, option, action) => {
    option && setValues({...values, provincia: option});
  }

  const handleMunicipioChange = (event, option, action) => {
    option && setValues({...values, municipio: option});
  }

  const handleLocalidadChange = (event, option, action) => {
    option && setValues({...values, localidad: option});
  }

  return (
    <>
      {state.provincias.length > 0 &&
        <div className="leaflet-top leaflet-right">
          <div className="leaflet-control leaflet-bar" style={{width: "300px", backgroundColor: "white"}}>
            <MapSelectGiz 
              name="provincia"
              onChange={handleProvinciaChange}
              items={state.provincias}
              helperText={"seleccione una provincia"}
              caption="Provincia"
              value={values.provincia}
            />
            <MapSelectGiz 
              name="municipio"
              onChange={handleMunicipioChange}
              items={state.municipios}
              helperText={"seleccione un minicipio"}
              caption="Municipio"
              value={values.municipio}
            />
            <MapSelectGiz 
              name="localidad"
              onChange={handleLocalidadChange}
              items={state.localidades}
              helperText={"seleccione una localidad"}
              caption="Localidad"
              value={values.localidad}
            />
          </div>
        </div>
      }          
    </>
  )
}

