import { Button, ButtonBase, IconButton } from "@material-ui/core";
import { Delete, Edit, ListAlt } from "@material-ui/icons";
import React from "react";
import { useRef } from "react";
import * as yup from "yup";
import { DatePicker, SelectGiz, TextGiz, TextNumberGiz, ColorGiz, SwitchGiz, MultiSelectGiz, SimpleSelectGiz, FakeControlGiz, CustomProperties, MapGiz } from '../app/helpers';
import { ObjectList } from "../app/helpers/ObjectList";

export const toDispatch = (type, payload) => ({type,payload});

export const baseAction = ( {fn, REQUEST, SUCCESS, FAILURE, getState, dispatch}) => { 
  console.log({fn, REQUEST, SUCCESS, FAILURE, getState, dispatch});
  dispatch(toDispatch(REQUEST));
  return new Promise((resolve, reject) => {
    fn()
    .then( response => {
      const data = response.data.value || response.data;
      console.log(dispatch(toDispatch(SUCCESS, data)));
      resolve(data);
    })
    .catch( error => {
      //console.log(error);
      dispatch(toDispatch(FAILURE, error));
      reject(error);
    });
  })
}

export const decodeToken = (token) => {
  try {
    if (token.split(".").length !== 3 || typeof token !== "string") {
      return null;
    } else {
      var payload = token.split(".")[1];
      var base64 = payload.replace("-", "+").replace("_", "/");
      var decoded = JSON.parse(atob(base64));
      return decoded;
    }
  } catch (error) {
    return null;
  }
}

export const dias = {
  dom: {text: "DOMINGO", value: "dom", active: true},
  lun: {text: "LUNES", value: "lun", active: true},
  mar: {text: "MARTES", value: "mar", active: true},
  mie: {text: "MIERCOLES", value: "mie", active: true},
  jue: {text: "JUEVES", value: "jue", active: true},
  vie: {text: "VIERNES", value: "vie", active: true},
  sab: {text: "SABADO", value: "sab", active: true},
}

export const currencyFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
});

export const price = {
  type: 'number',
  valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  cellClassName: 'font-tabular-nums',
};

export const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
  return [ htmlElRef, setFocus ] 
}

/**
 * 
 * @param {*} param0 [{name: string, type: string, [required: bool, min: number, max: number]}]
 * @returns yup.ObjectSchemaConstructor
 */
 export const createValidationObject = ({fields = []}) => {

  const resolveType = (params) => {

    const { type } = params;

    //if(type==="objectList") return {...params, field: yup.array(createValidationObject(params))};
    //if(type==="customProperties") return {...params, field: createValidationObject(params)};

    if(yup[type]){
      return {...params, field: yup[type]().nullable()};
    }else{
      switch(type){
        case "email":
          return {...params, field: yup.string().email("El email ingresado es inválido.").nullable()};
        case "N":
          return {...params, field: yup.number().nullable()};
        default: 
          return {...params, field: yup.string().nullable()};
      }
    }
  }

  const resolveRequired = (params) => {
    const { field, validation } = params;
    return {...params, field: !validation || !validation.required ? field : field.required("El dato es requerido.")};
  }

  const resolveMin = (params) => {
    const { field, type, validation } = params;
    return {...params, field: !validation || !validation.min ? field : field.min( validation.min, type === "string" ? `Debe ingresar al menos ${validation.min} caracteres` : `El valor mínimo es ${validation.min}` )};
  }

  const resolveMax = (params) => {
    const { field, type, validation } = params;
    return {...params, field: !validation || !validation.max ? field : field.max( validation.max, type === "string" ? `Puede ingresar hasta ${validation.max} caracteres` : `El valor máximo es ${validation.max}` )};
  }

  const createValidationField = (params) => {
    return resolveMax(
      resolveMin( 
        resolveRequired( 
          resolveType( params  )
        )
      )
    ).field;
  }
  let validations = {};
  fields && fields.map((x, i) => {
    validations = {...validations, [x.name]: createValidationField({...x})}
  });
  return yup.object(validations);
}

export const createConfig = (featureAccess) => {

  const resolveFeature = (feature, controllers) => {
    if(feature.features != null){
      resolveSection(feature, controllers);
    }else{
      controllers.push({...feature, columns: createColumns(feature), name: feature.name , caption: feature.caption});
    }
  }

  const resolveSection = (section, controllers) => {
    section && section.features && section.features.forEach( x => resolveFeature(x, controllers));
  }


  const calc = () => {
    let controllers = [];
    if(featureAccess && featureAccess.menu) {
      featureAccess.menu.sectionRoot && resolveSection(featureAccess.menu.sectionRoot, controllers);
      featureAccess.menu.sections && featureAccess.menu.sections.forEach(x => resolveSection(x, controllers));
    } 
    return {controllers};
  }
  return calc();
}

export const createColumns = (feature) => ({onEdit, onDelete, onDetails, extraActions, extraData}) => {
  const { fields, disabled = false } = feature;
  let columns = !fields ? [] : fields.filter(x => x.list).map(x => {
      const render = Boolean(x.render) ? eval(x.render) : undefined;
      console.log({r:x.render, render})
      switch(x.type){
        case "boolean":{
          return {field: `boolean_${x.name}`, headerName: x.caption, width: x.columnWidth, flex: x.flex, renderCell: render ? (params) => render(params, extraData) : (params) => (params.row[x.name] ? "SI" : "NO")}
        }
        default:{
          return {field: x.name, headerName: x.caption, width: x.columnWidth, flex: x.flex, renderCell: render ? (params) => render(params, extraData) : undefined};
        }
      }
    }
  );
  if(columns.length > 0 && !disabled) {
    columns.push(
      { field: 'acciones', headerName: 'Acciones', width: 150, renderCell: (params) => {
        return (
          <>
            <IconButton onClick={(event)=>onDetails(params, event)}>
              <ListAlt/>
            </IconButton>
            <IconButton onClick={(event)=>onEdit(params, event)}>
              <Edit/>
            </IconButton>
            <IconButton onClick={(event)=>onDelete(params, event)}>
              <Delete/>
            </IconButton>
            {extraActions}
          </>)
      }}  
    )
  }
  return columns;
}

const types = {
  string: TextGiz,
  number: TextGiz,
  email: TextGiz,
  date: DatePicker,
  dropdown: SimpleSelectGiz,
  customProperties: (props) => {
    const { name, onChange, formik } = props;
    return (
      <CustomProperties 
        {...props} 
        onChange={(values) => {
          const arrayValues = Object.keys(values).map(x => ({name: x, value: values[x]}));
          formik && formik.setFieldValue(name, arrayValues);
          onChange && onChange(arrayValues);
        }}
        formik={undefined}
      />
    )
  },
  objectList: ObjectList,
  key: undefined,
  transferList: undefined,
  hidden: undefined,
  color: ColorGiz,
  boolean: SwitchGiz,
  multiSelect: MultiSelectGiz,
  fake: FakeControlGiz,
  map: MapGiz,
  default: undefined,
}

export const getFieldForm = ({type}) => {
  return types[type] || types.default;
}

export const getField = ({fields, name}) => {
  return fields.some(x => x.name && x.name === name) && fields.filter(x => x.name === name)[0];
}

const toPropertyCasex = (text) => [...text].map((l, i) => i === 0 ? l.toLowerCase() : l ).join("");