import React from "react";
import { Grid } from "@material-ui/core";
import { getFieldForm } from "../../redux/utils";

export const Field = (props) => {

  const { fieldFn } = props;

  //const hide = fnHide && fnHide({item, data, field, details})

  const { details = false, fake = false, field, formik = {}, data = {}, item = {} } = fieldFn ? eval(fieldFn)(props) : props;
  
  const controlForm = getFieldForm({type: field.type}); 

  const FieldGiz = fake && controlForm ? getFieldForm({type: "fake"}) : controlForm;

  return (
    <>
      {FieldGiz &&
        <Grid item xs={12} md={field.columns}>
          <FieldGiz {...{formik, data, item, formik, details}} {...field}/>
        </Grid>    
      }
    </>
  )
}
