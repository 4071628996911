import { Button, CardContent, CardHeader } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import DetailsForm from './DetailsForm';

import useStyles from './Style';

const Details = (props) => {
  const { config } = props;
  let history = useHistory();
  const classes = useStyles();

  const handleBackClick = (event) => {
    event.preventDefault();
    history.goBack();
  }

  return (
    <>
      <CardHeader
        action={
          <Button onClick={handleBackClick}>
            Volver
          </Button>
        }
        title={config.caption}
        subheader="Detalles"
      />
      <DetailsForm {...props}/>
    </>
  );
};

export default Details;