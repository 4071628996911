/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Route } from "react-router-dom";
import { Logout } from "./controllers/account/Logout";
import { useAuthContext } from "./contexts/Auth";
import { RedirectToHome } from "./contexts/FeaturesAccess";
import { Layout } from "../_metronic/layout";
import { useEffect } from "react";
import { toAbsoluteRouterUrl } from "../_metronic/_helpers";
import { connect } from "react-redux";
import { Abm } from "./controllers/abm/Index";


const _PrivateRoutes = ({config}) => {
  const {auth, reLogin} = useAuthContext();

  //se renueva el token cada 30 minutos
  useEffect(()=>{
    const intervalId = setInterval(()=>{reLogin()}, 30*1000*60);
    return ()=>{clearInterval(intervalId)}   
  }, [])

  return (
    <Layout>
      <Route path={toAbsoluteRouterUrl("logout")} component={Logout} />
      {
        config.controllers.map(x => 
          <Route path={[
            toAbsoluteRouterUrl(`Abm/${x.name}/:action/:id`),
            toAbsoluteRouterUrl(`Abm/${x.name}/:action`),
            toAbsoluteRouterUrl(`Abm/${x.name}`)
          ]}>
            <AbmComponent {...x}/>
          </Route>
        )
      }
      <Route path="*" component={RedirectToHome}/> 
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
}

export const PrivateRoutes = connect(mapStateToProps)(_PrivateRoutes);


const AbmComponent = (props) => {
  const Comp = Abm({controller: props.name});
  return (
    <Comp />
  );
}
