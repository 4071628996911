import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Field } from "./";
import { createValidationObject } from '../../redux/utils';
import { Grid } from "@material-ui/core";

export const CustomProperties = (props) => {
  const { details = false, data, name, item, fields, config, initialValues = undefined } = props;
  const validationSchema = createValidationObject({fields});
  const [loaded, setLoaded] = useState(false);
  const parentFormikSubmitCount = props.formik && props.formik.submitCount;

  const formik = useFormik({
    initialValues: initialValues ? initialValues : {},
    validationSchema: validationSchema,
    validateOnChange: true,
  });

  useEffect(()=>{
    if(!initialValues){
      const values = fields.reduce((p, c) => {
        const subitem = item[name] && item[name].filter(x => x.propiedad.nombre.toLowerCase() === c.name.toLowerCase());
        return subitem && subitem.length > 0 ? {...p, [c.name]: subitem[0].valor} : p;
      }, {});
      formik.setValues(values);  
    }
    setLoaded(true);
  }, []);

  useEffect(()=>{
    if(parentFormikSubmitCount > 0){
      formik.submitForm();
    }
  }, [parentFormikSubmitCount]);

  useEffect(()=>{
    props.formik && props.formik.setFieldValue(name, formik.values);
    props.onChange && props.onChange(formik.values);
  }, [formik.values]);

  return (
    <>
      <Grid container spacing={2}>
        {loaded &&
          fields.map(x => 
            <Field {...{formik, data, item: item[name], fields: fields[name], details }} field={x}  />
          )
        }
        {!loaded && 
          fields.map(x=>
            <Field fake={true} field={x}/>
          )
        }
      </Grid>
    </>
  );
}