import React from 'react';
import { List, ListItem, ListItemText, Box, ListItemAvatar, Avatar, ListItemSecondaryAction} from '@material-ui/core';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import moment from "moment";

const itemStyle = {marginTop: "8px", backgroundColor: "#2aaede05" }


export const SortableList = ({details, onReorderList, rows, columns, addItem}) => {

  const onSortEnd = ({oldIndex, newIndex}) => {
    onReorderList(arrayMove(rows, oldIndex, newIndex));
  };

  const handleBeforeSorting = (event) => {
    //console.log(event);
    return details ? true : ["svg", "path"].some(x => x === event.target.tagName);
  }

  const SortableContainerFn = SortableContainer(({children}) => {
    return (
      <List> 
        {children}
        {addItem && !details && <ListItem style={{...itemStyle, border: "1px #2aaede dashed"}}>{addItem}</ListItem>}
      </List>
    );
  });
   
  const SortableItemFn = SortableElement(({item}) => {
    const primaryText = columns
      .filter(x => x.field !== "acciones")
      .map(c => c.renderCell ? c.renderCell({row: item}) : ( item[c.field] ? item[c.field] : "" ) )
      .filter(x => Boolean(x)).join(", "); 
    
    return (
      <div key={item.index}>
        <ListItem  style={{...itemStyle, border: "1px #2aaede solid", backgroundColor: "white", opacity: 1}}>
          <ListItemAvatar>
            <Avatar>
              {item.index + 1}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={primaryText}/>
          {!details && columns.filter(x => x.field === "acciones")[0] &&
            <ListItemSecondaryAction>
              {columns.filter(x => x.field === "acciones")[0].renderCell({row: item})}
            </ListItemSecondaryAction>      
          }
        </ListItem>
      </div>      
    )

  });


  return (
    <Box style={{ width: "100%"}}>
      <SortableContainerFn shouldCancelStart={handleBeforeSorting} onSortEnd={onSortEnd}> 
        {rows && rows.map((item, index) => (
          <SortableItemFn key={`item-${index}`} index={index} item={{...item, index}} />
        ))}
      </SortableContainerFn>
    </Box>
  );
}