import React from 'react';
import { List, ListItem, ListItemText, Box, ListItemSecondaryAction} from '@material-ui/core';

const itemStyle = {marginTop: "8px", backgroundColor: "#2aaede05" }

export const UnsortableList = ({details, rows, columns, addItem, data}) => {
  return (
    <Box style={{ width: "100%"}}>
      <List> 
        {rows && rows.map((item, index) => {
          console.log({item, columns, data});
          const primaryText = columns
            .filter(x => x.field !== "acciones")
            .map(c => c.renderCell ? c.renderCell({row: item, extraData: data}) : ( item[c.field] ? item[c.field] : "" ) )
            .filter(x => Boolean(x)).join(", "); 
          return (
            <div key={`item-${index}`}>
              <ListItem  style={{...itemStyle, border: "1px #2aaede solid", backgroundColor: "white", opacity: 1}}>
                <ListItemText
                  primary={primaryText}
                />
                {!details && columns.filter(x => x.field === "acciones")[0] &&
                  <ListItemSecondaryAction>
                    {columns.filter(x => x.field === "acciones")[0].renderCell({row: item})}
                  </ListItemSecondaryAction>      
                }
              </ListItem>
            </div>
          )
        })}
        {addItem && !details && <ListItem style={{...itemStyle, border: "1px #2aaede dashed"}}>{addItem}</ListItem>}
      </List>
    </Box>
  );
}