import { FormControl, FormControlLabel, makeStyles, Switch } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { DetailWrapperGiz } from "./";


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));


export const SwitchGiz = ({details = false, name, caption, formik, initialState = null, title, readOnly = true, onChange}) => {

  const [timer, setTimer] = useState(null);

  const [value, setValue] = useState(false);
  
  useEffect(() => {
    setValue(formik ? Boolean(!(formik.values[name] === "false" || formik.values[name] === "")): initialState);
    return () => clearTimeout(timer);
  }, [])
  
  useEffect(()=>{
    timer !== null && clearTimeout(timer);
    setTimer( 
      formik ? setTimeout(actualizarFormik, 50) : onChange({target:{name, value: value}})
    );
  }, [value])

  const actualizarFormik = () => {
    value !== Boolean(formik.values[name]) && formik.setFieldValue(name, value);
  }

  const handleInputChange = () => {
    setValue(!value)
  }
  
  const classes = useStyles();

  if(details){
    return (
      <>
        <DetailWrapperGiz caption={caption}>{formik.values[name] ? "Sí" : "No"}</DetailWrapperGiz>
      </>
    )
  }

  return (
    <FormControl fullWidth  variant="outlined" className={classes.formControl} style={{margin:"4px 8px 4px 8px"}}>
      <FormControlLabel 
        control={
          <Switch
            title={title}
            variant="outlined"
            id={name}
            name={name}
            color="primary" 
            readOnly={readOnly}
            checked={value} 
            onChange={handleInputChange}
            value={true}/>
        }
        label={caption}
        labelPlacement="end"
      />
    </FormControl>
  )
}
