import React from "react";
import { Skeleton } from "@material-ui/core";
import { FormControl, InputLabel } from '@material-ui/core'

export const FakeControlGiz = ({caption}) => {
  return (
    <div style={{ margin: 8 }}>
      <FormControl fullWidth variant="outlined" >
        <InputLabel shrink={true} style={{paddingLeft: "4px", paddingRight: "4px"}}>
          {caption}
        </InputLabel>      
        <Skeleton variant="rectangular" height={52} />
      </FormControl>
    </div>

  )
}