import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSnackContext } from '../../contexts/Snack'
import { useHistory, useParams } from 'react-router';
import { Backdrop, Button, CardContent, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { createValidationObject } from '../../../redux/utils';
import { Field } from '../../helpers';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: '#fff',
    opacity: 1,
    backgroundColor: "#000000"
  },
}));


export const Form = (props) => {
  const { loaded, editForm, addForm, loadedProcessed } = props;
  let { id } = useParams();
  const [internalLoaded, setInternalLoaded] = useState(false);
  useEffect(() => {
    const fn = id ? ()=>editForm(id) : addForm;
    fn()
      .then(response => {
      })
      .catch(error=> {
      });
  }, [])

  useEffect(() => {
    if(loaded){
      loadedProcessed()
      setInternalLoaded(true); 
    }
  }, [loaded])

  return (
    <>
      {internalLoaded && <RealForm {...{...props, ...{id}}}/>}
      {!internalLoaded && <FakeForm {...props}/>}
    </>
  )
}


export const FakeForm = (props) => {
  const { config } = props;
  return (
    <>
      <CardContent>
        <Grid container spacing={2}>
          {
            config.fields.map(x=>
              <Field fake={true} field={x} />
            )
          }
        </Grid>
      </CardContent>
    </>
  );
}

export const RealForm = (props) => {

  const classes = useStyles();
  
  let history = useHistory();
  const { snack, id, config, item, data, editItem, addItem } = props;

  const handleSaveClick = (event) => {
    event.preventDefault();
    formik.submitForm();
  }

  const formik = useFormik({
    initialValues: item,
    validationSchema: createValidationObject({fields: config.fields}),
    validateOnChange: true,
    onSubmit: values => {
      //console.log(values);
      formik.setSubmitting(true);
      const fn = id ? () => editItem(id, values) : () => addItem(values);
      fn().then(response => {
        snack.openSuccess(`El elemento se ${id ? 'editó' : 'agregó'} correctamente.`,);
        history.replace(`/${config.url}`);
      }).catch(error => {
        snack.openError('Error de servidor.');
        formik.setSubmitting(false);
      })
    },
  });

  return (
    <>
      <CardContent>
        <Grid container spacing={2}>
          {
            config.fields.map(x=>
              <Field {...{formik, data, item}} field={x} />
            )
          }
          <Grid item xs={12}>
            <Button style={{margin: 8}} variant="contained" color="primary" onClick={handleSaveClick} disabled={!formik.isValid || formik.isSubmitting} >Guardar</Button>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}

export default Form;

