import React, { useEffect, useState } from "react"
import { TextField } from "@material-ui/core"
import { DetailWrapperGiz } from "./";

export const TextGiz = ({defaultValue, name, details = false, caption, formik, placeholder, type, autoFocus, disabled, style = {}, inputProps = {}, multiline = false, rows = undefined}) => {

  const error = formik && formik.errors[name];
  const hasError = Boolean(error);
  const [inputValue, setInputValue] = useState(formik.values[name] || (defaultValue && eval(defaultValue)))
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
    timer && clearTimeout(timer);
    setTimer(setTimeout(()=>formik.setFieldValue(name, inputValue), 250));
  }, [inputValue])

  if(details){
    return (
      <>
        <DetailWrapperGiz caption={caption}>{formik.values[name]}</DetailWrapperGiz>
      </>
    )
  }
  
  const handleInputChange = async (e) => {   
    e.preventDefault(); 
    //formik.setFieldValue(name, e.target.value)
    setInputValue(e.target.value)
  }

  return (
    <div style={{ margin: 8 }}>
      <TextField
        rows={rows}
        multiline={multiline}
        autoFocus={autoFocus}
        fullWidth
        type={type}
        variant="outlined"
        id={name}
        name={name}
        label={caption}
        style={{ ...style }}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        error={hasError}
        helperText={error}
        disabled={disabled} 
        inputProps={inputProps}
      />
    </div>
  )
}

