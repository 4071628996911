import React from "react";
import { Box, FormControl, FormHelperText, InputLabel } from "@material-ui/core";

export const DetailWrapperGiz = ({caption, children, helperText = ""}) => {
  return (
    <div  style={{ margin: 8 }}>
      <FormControl fullWidth variant="outlined"  style={{ border: "1px solid #c4c4c4", borderRadius: 4, minHeight: 53.5 }}>
        <InputLabel shrink={true} style={{paddingLeft: "4px", paddingRight: "4px"}}>
          {caption}
        </InputLabel>      
        <Box margin={2} >{children}{' '}</Box>
        {helperText &&
          <FormHelperText>{helperText}</FormHelperText>
        }
      </FormControl>
    </div>
  )

}
