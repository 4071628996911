import React from "react";
import { Switch, Route } from "react-router-dom";
import { Token } from "./controllers/Token";
import { Login } from "./controllers/account/Login";
import { useAuthContext } from "./contexts/Auth";
import { useFeatureAccessContext } from "./contexts/FeaturesAccess";
import { toAbsoluteRouterUrl } from "../_metronic/_helpers";
import { PrivateRoutes } from "./PrivateRoutes";
import Store from "./contexts/Store";
import useStore from "../redux/store";
import { useSnackContext } from "./contexts/Snack";

export const Routes = () => {
  const {auth, reLogin} = useAuthContext();
  const featureAccess = useFeatureAccessContext();
  const { open, openError, openSuccess, openAxiosError } = useSnackContext();
  const store = useStore({
    auth: auth,
    config: {...featureAccess.config, snack: {open, openError, openSuccess, openAxiosError}},
  });
  return (
    <>
      {auth.loaded && 
        <Switch>
          <Route path={toAbsoluteRouterUrl("Token/:paramToken")} exact={true} component={Token} />
          {auth.isAuthenticated &&
            <Store {...{store}}>
              <PrivateRoutes />
            </Store>
          }
          {!auth.isAuthenticated &&
            <>
              <Route path={toAbsoluteRouterUrl("Login")} exact={true} component={Login} />
              <Route path="*" component={Login}/> 
            </>
          }
        </Switch>
      }
    </>
  );
}