/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
//import { useLocation } from "react-router";
//import { checkIsActive } from "../../../../_helpers";
import { usePageTitleContext } from "../../../../../app/contexts/PageTitle";

export function HeaderCustomMenu({ layoutProps }) {

    const {title, subTitle} = usePageTitleContext().header;
    /*const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }*/

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile container-fluid ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        <div style={{paddingTop:"20px"}}><h3 className="text-dark font-weight-bold mr-5" id="page_title">{title} <span className="text-muted" style={{fontSize: "12px"}}>{subTitle}</span></h3></div>
        {/*end::Header Nav*/}
    </div>;
}
