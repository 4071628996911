import { combineReducers } from "redux";
import { authReducer } from "./auth.reducer";
import { reducer as abm } from "./abm.reducer";
import { configReducer } from "./config.reducer";
/**
 * 
 * @param {auth, config} lista de config {}: auth, controllers: [{name: string}]
 * @returns lista de reducers combinados
 */

const createRootReducer = ({auth, config}) => combineReducers({
  ...config.controllers.reduce((a, c) => ({...a, [c.name]: abm(c.name)}), {}),
  auth: authReducer(auth), 
  config: configReducer(config),
})

export default createRootReducer;