import React, { useContext, useEffect, useState } from "react";
import { Alert, Snackbar } from "@material-ui/core";

const useSnack = () => {
  
  const [state, setSnackState] = useState({
    open: false, 
    text: "", 
    vertical: 'top', 
    horizontal: 'center', 
    onClose: () => {},
    duration: 10000
  });

  const open = ({text, type, duration = 10000, onClose = ()=>{}}) => {
    setSnackState({...state, open: true, text: text, onClose: onClose ? onClose : null, duration: duration, type: type})
  }
  
  const openError = (text, onClose = ()=>{}) => {
    setSnackState({...state, open: true, text: text, onClose: onClose ? onClose : null, duration: 10000, type: "error"})
  }

  const openAxiosError = (error, formik, onClose = ()=>{}) =>{

    if(formik && error && error.response && error.response.data && error.response.data.errors){
      formik.setErrors(error.response.data.errors);
    }

    if(error && error.response && error.response.data && error.response.data.title){
      openError(error.response.data.title, onClose)
      return;
    }

    //console.log("error");
    //console.log(error.response);
    if(!error || !error.request || !error.request.status) {
      openError("Error!.", onClose)
      return;
    }
    //console.log("error2");

    if(error.request.status >= 500){
      openError("Error de servidor.", onClose);
      return;
    }
    //console.log("error3");

    switch(error.request.status){
      case 400: {
        openError("Error de validación de datos.", onClose);
        break;
      }
      case 401: {
        openError("Debe loguearse nuevamente para completar la operación.", onClose)
        break;
      }    
      case 403: {
        openError("No está autorizado para acceder a este contenido.", onClose)
        break;
      }    
      case 404: {
        openError("Esta información no se ha encontrado o ya no se encuentra disponible.", onClose)
        break;
      }    
      case 408: {
        openError("El servidor tardó demasiado en responder.", onClose)
        break;
      }   
      case 410: {
        openError("Este contenido fue eliminado.", onClose)
        break;
      }   
      case 429: {
        openError("Demasiadas peticiones simultaneas.", onClose)
        break;
      } 
      default: {
        openError(`Error de consulta no especificado. (${error.response.status})` , onClose)
      }
    }
  }

  const openSuccess = (text, onClose = ()=>{}) =>{
    setSnackState({...state, open: true, text: text, onClose: onClose ? onClose : null, duration: 1000, type: "success"})
  }

  const handleOnClose = () => {
    state.onClose && state.onClose();
    setSnackState({...state, open: false, text: "", onClose: () => {}})
  }

  return { 
    open,
    openSuccess,
    openError,
    openAxiosError,
    state,
    component:     
      ({state}) => <Snackbar
        anchorOrigin={{ vertical: state.vertical, horizontal: state.horizontal }}
        key={`${state.vertical},${state.horizontal}`}
        open={state.open}
        //onClose={handleOnClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id" ><div dangerouslySetInnerHTML={ { __html: state.text } }></div></span>}
        autoHideDuration={state.duration}
        onClose={handleOnClose}
        color="green"
      >
        <Alert onClose={handleOnClose} severity={state.type} sx={{ width: '100%' }}>
          <div dangerouslySetInnerHTML={ { __html: state.text } }></div>
        </Alert>
      </Snackbar>
  };
}

const SnackContext = React.createContext(useSnack); 

export const useSnackContext = () => { 
  return useContext(SnackContext); 
};

export const SnackProvider = ({ children }) => {
  const snack = useSnack();
  const [state, setState] = useState({loaded: false, snack: undefined, SnackComponent: undefined})
  useEffect(()=>{
    setState({loaded: true, snack: snack, SnackComponent: snack.component});
  }, [])
  return (
    <>
    {state.loaded && 
      <>
        <SnackContext.Provider value={state.snack}>
          {children}
        </SnackContext.Provider>
        <state.SnackComponent state={snack.state}/>
      </>
    }
    </>
  );
};