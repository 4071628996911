
import { abmConstants } from "../constants";

const defaultState = {
  isFetching: false, 
  list: [],
  data: [],
  item: {
    id: 0,
  },
  loaded: false,
};

/**
 * 
 * @param {*} controller string | nombre del controller de la api 
 * @returns un reducer de operaciones asincronicas
 */
export const reducer = (controller) => (state = defaultState, {type, payload}) => {
  
  const constants = abmConstants(controller);

  switch(type){
    case constants.INDEX.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.INDEX.SUCCESS:
      return {
        ...state,
        list: payload,
        isFetching: false, 
      };      
    case constants.INDEX.FAILURE:
      return {
        ...state,
        list: [],
        isFetching: false, 
      };  
    case constants.GET_ITEM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.GET_ITEM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
      };      
    case constants.GET_ITEM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.ADD_ITEM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.ADD_ITEM.SUCCESS:
      return {
        ...state,
        list: [payload, ...state.list],
        isFetching: false, 
      };      
    case constants.ADD_ITEM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.ADD_FORM.REQUEST:
      return {
        ...state,
        loaded: false,
        isFetching: true, 
      };
    case constants.ADD_FORM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
        loaded: true,
        ...payload,
      };      
    case constants.ADD_FORM.FAILURE:
      return {
        ...state,
        loaded: false,
        isFetching: false, 
      };  
    case constants.EDIT_ITEM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.EDIT_ITEM.SUCCESS:
      return {
        ...state,
        list: [],
        isFetching: false, 
      };      
    case constants.EDIT_ITEM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.EDIT_FORM.REQUEST:
      return {
        ...state,
        isFetching: true, 
        loaded: false,
      };
    case constants.EDIT_FORM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
        loaded: true,
        ...payload,
      };      
    case constants.EDIT_FORM.FAILURE:
      return {
        ...state,
        loaded: false,
        isFetching: false, 
      };  
      case constants.REMOVE_ITEM.REQUEST:
        return {
          ...state,
          isFetching: true, 
        };
      case constants.REMOVE_ITEM.SUCCESS:
        return {
          ...state,
          list: state.list.filter(x => x.id !== payload),
          isFetching: false, 
        };      
      case constants.REMOVE_ITEM.FAILURE:
        return {
          ...state,
          isFetching: false, 
        };  
      case constants.LOADED_PROCESSED:
        return {
          ...state,
          loaded: false,
        }
            
    default:
      return state;
  }
}