import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import abmActions from '../../../redux/actions/abm.actions';
import { useParams } from 'react-router';
import Add from './Add';
import Edit from './Edit';
import List from './List';
import Details from './Details';
import { Card } from '@material-ui/core';
import useStyles from './Style';

const actions = {
  Edit: (params) => <Edit {...params} />,
  Add: (params) => <Add {...params}  />,
  Details: (params) => <Details {...params} />,
  default: (params) => <List {...params} />,
}

const getAction = (action) => actions[action] ? actions[action] : actions.default;

const _Abm = (props) => {
  const {controller} = props;
  const classes = useStyles();
  let { action = "List", id = "0" } = useParams();
  const [state, setState] = useState({loaded: false, actionFn: () => {}})

  useEffect(()=>{
    setState({...state, loaded: true, actionFn: getAction(action)});
  },[controller, action, id])

  return (
    <>
      {state.loaded && 
        <Card className={classes.card}>
          {state.actionFn(props)}
        </Card>    
      }
    </>
  );

}

const mapStateToProps = (controller) => (state) => {
  return {
    controller,
    auth: state.auth,
    config: state.config.controllers.filter(x => x.name === controller).pop(),
    snack: state.config.snack,
    ...state[controller],
  };
}

const mapDispatchToProps = (controller) => ({
  ...abmActions(controller)
});

/**
 * 
 * @param {*} controller string
 * @returns componente conectado a redux
 */
export const Abm = ({controller}) => connect(mapStateToProps(controller), mapDispatchToProps(controller))(_Abm);
